/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { UNFOLLOW_USER_MUTATION } from 'src/queries/membership';
import { User } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { updateCurrentUser, updatePaginatedUsers } from 'src/utils/data';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';

export function useUnfollowUser(
  followed: User
): (_?: any, options?: any) => Promise<void> {
  const ensureUser = useEnsureUser();
  const { t } = useTranslation('general');
  const [unfollowUserMutation] = useMutation(UNFOLLOW_USER_MUTATION, {
    update(cache, { data: { unfollowUser } }) {
      updateCurrentUser(cache, (currentUser: User) => {
        return {
          ...currentUser,
          ...unfollowUser.follower,
        };
      });

      cache.writeFragment({
        id: `User:${followed.id}`,
        fragment: gql`
          fragment post on Post {
            userFollowerCount
            userHasFollowed
          }
        `,
        data: {
          userFollowerCount: followed.userFollowerCount - 1,
          userHasFollowed: false,
        },
      });

      updatePaginatedUsers(
        cache,
        {
          followerId: unfollowUser.follower.id,
        },
        (users) => {
          return users.filter((user) => {
            return user.id !== unfollowUser.followed.id;
          });
        }
      );
    },
  });
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_?: any, options?: any): Promise<void> => {
    try {
      if (!unfollowUserMutation) return;

      await ensureUser();

      await unfollowUserMutation({
        variables: {
          followedId: followed.id,
        },
      });
      toast(
        t('followUnfollowed', {
          username: followed.username,
        })
      );
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (options && options.setSubmitting) options.setSubmitting(false);
    }
  };
}
