import { useMutation } from '@apollo/client';
import {
  CURRENT_USER_QUERY,
  PURCHASE_USER_SUBSCRIPTION_MUTATION,
} from 'src/queries';
import { CuratedList, PurchaseUserSubscriptionInput, User } from 'src/types';
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import gql from 'graphql-tag';

export function usePurchaseUserSubscription(): (
  purchaseUserSubscriptionInput: PurchaseUserSubscriptionInput
) => Promise<CuratedList> {
  const ensureUser = useEnsureUser();
  const [purchaseUserSubscriptionMutation] = useMutation(
    PURCHASE_USER_SUBSCRIPTION_MUTATION,
    {
      update(cache, { data: { purchaseUserSubscription } }) {
        const { currentUser } = cache.readQuery({
          query: CURRENT_USER_QUERY,
        }) as { currentUser: User };
        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: {
              ...currentUser,
              ...purchaseUserSubscription.follower,
              activeUserSubscriptions: [
                ...currentUser.activeUserSubscriptions,
                purchaseUserSubscription.userSubscription,
              ],
            },
          },
        });

        cache.writeFragment({
          id: `User:${purchaseUserSubscription.followed.id}`,
          fragment: gql`
            fragment post on Post {
              followerCount
              userHasFollowed
            }
          `,
          data: {
            followerCount: purchaseUserSubscription.followed.followerCount,
            userHasFollowed: true,
          },
        });
      },
    }
  );

  return async (
    purchaseUserSubscriptionInput: PurchaseUserSubscriptionInput
  ): Promise<CuratedList> => {
    if (!purchaseUserSubscriptionMutation)
      throw new Error("purchaseUserSubscriptionMutation didn't load");

    await ensureUser();

    const {
      data: { purchaseUserSubscription },
    } = await purchaseUserSubscriptionMutation({
      variables: {
        purchaseUserSubscriptionInput,
      },
    });

    return purchaseUserSubscription;
  };
}
