import { gql } from '@apollo/client';

const postDef = `
  id: ID!
  insertedAt: Datetime!
  accessType: Int!
  likeCount: Int!
  commentCount: Int!
  curatedListCount: Int!
  deletedAt: Date
  topLevelCommentCount: Int!
  description: String
  imageCount: Int!
  isApproved: Boolean!
  price: Int!
  purchaseCount: Int!
  publishAt: Date!
  videoCount: Int!
  videoDuration: Int!
  title: String
  credits: [Credit!]
  comments: [Comment!]
  features: [Feature!]
`;

const userDef = `
  id: ID!
  postCount: Int!
  commentCount: Int!
  likeCount: Int!
  curatedPostCount: Int!
  curatedListCount: Int!
  curatedListFollowingCount: Int!
  displayName: String
  hasSubmittedCreatorApplication: Boolean!
  notifications: [Notification!]
  pronouns: String
  purchaseCount: Int!
  subscriptionPrice: Float!
  subscriptionPostCount: Int!
  unseenNotificationCount: Int!
  userFollowerCount: Int!
  userFollowingCount: Int!
  subscribedCount: Int!
  subscriberCount: Int!
  username: String!
  userHasFollowed: Boolean!
  activeUserSubscriptions: [UserSubscription!]
  creatorApplications: [CreatorApplication!]
  avatar: Image
  comments: [Comment!]
  credits: [Credit!]
  curatorialMemberships: [CuratorialMembership!]
  moderationRequestResponses: [ModerationRequestResponse!]
  permissions: Permissions!
  profile: Profile!
  restrictions: Restrictions!
`;

const imageDef = `
  id: ID!
  caption: String
  filterIntensity: Int
  src: String
  previewSrc: String
  previewType: Int
`;

const mediaCollectionDef = `
  id: ID!
`;

const mediaItemDef = `
  id: ID!
  previewable: Boolean!
  order: Int!
  mediaCollection: MediaCollection
`;

const videoDef = `
  id: ID!
  caption: String
  filterIntensity: Int
  duration: Int!
  src: String
  previewSrc: String
  previewType: Int
`;

export default gql`
  scalar Date
  scalar Datetime
  
  schema {
    query: Query
    mutation: Mutation
  }

  type Query {
    post(id: ID!): Post
    curatedList(id: ID!): CuratedList
    currentUser: User
    offsetBasedPaginatedEntries(metadata: PaginationMetadataInput, paginationArgs: PaginationArgsInput, type: String): [PaginatedEntry!]!
    tagSuggestions(metadata: PaginationMetadataInput, query: String): [Tag!]
    userSuggestions(metadata: PaginationMetadataInput, query: String): [User!]
    userByUsrname(username: String!): User!
  }

  type Mutation {
    acceptCredit(creditId: String!): Credit!
    likePost(postId: String!): PostLike!
    addToCuratedList(postId: String!, curatedListId: String!): CuratedPostListing!
    createPost(post: PostInput!): PostResponse!
    createCreatorApplication(creatorApplication: CreatorApplicationInput!): CreatorApplication!
    createComment(comment: CommentInput!): CommentMutation!
    createCuratedList(name: String!): CuratedList!
    createUser(email: String, password: String, username: String): Session!
    declineCredit(creditId: String!): Credit!
    unlikePost(postId: String!): PostLike!
    defeatureCuratedList(curatedListId: String!): CuratedList!
    deleteComment(commentId: ID!): CommentMutation!
    featureCuratedList(curatedListId: String!): CuratedList!
    followCuratedList(followedId: String!): CuratedListFollow!
    followUser(followedId: String!): UserFollow!
    login(identity: String, password: String): Session!
    purchasePost(postId: String!): PostPurchase!
    readNotification(notificationId: ID!): Notification
    removeFromCuratedList(curatedPostListingId: String!): CuratedPostListing
    respondToModerationRequest(moderationRequestResponse: ModerationRequestResponseInput!): ModerationRequest!
    markNotificationsAsSeen(notificationIds: [ID!]!): [Notification]
    submitModerationRequestReport(moderationRequestReport: ModerationRequestReportInput!): ModerationRequestReport!
    unfollowCuratedList(followedId: String!): CuratedListFollow!
    unfollowUser(followedId: String!): UserFollow!
    updateComment(comment: CommentUpdateInput!): CommentMutation!
    updateCuratedList(curatedListId: String!, description: String!, name: String!): CuratedList!
    updateProfile(id: String!, description: String, displayName: String, pronouns: String, website: String): User!
  }

  type CurrentUser {
    ${userDef}
  }

  type Permissions {
    canApproveCreatorApplications: Boolean!
    canFeatureLists: Boolean!
    canPostContent: Boolean!
    canModerateContent: Boolean!
  }

  type Restrictions {
    cannotPostAnything: Boolean!
    cannotPostComments: Boolean!
    cannotPostContent: Boolean!
    cannotModerate: Boolean!
  }

  type Session {
    token: String!
    user: User!
  }

  type User {
    ${userDef}
  }



  input PostInput {
    id: ID
    description: String
    price: Int
    title: String
    credits: [CreditInput!]
    mediaCollection: MediaCollectionInput
  }

  input CreatorApplicationInput {
    id: ID!
    birthday: Datetime!
    citizenship: Int!
    legalName: String!
  }

  input CommentInput {
    text: String!
    postId: ID!
    parentId: ID
  }
  
  input CommentUpdateInput {
    id: ID!
    text: String!
  }

  input CreditInput {
    id: ID
    canModify: Boolean!
    isPublisher: Boolean
    role: Int!
    share: Int!
    title: String
    username: String!
  }


  type CommentMutation {
    post: Post!
    comment: Comment!
    parent: Comment
  }
  
  type Post {
    ${postDef}
    mediaCollection: MediaCollection
  }
  
  type PostResponse {
    ${postDef}
    mediaCollection: MediaCollection
  }

  type CreatorApplication {
    id: ID!
    birthday: Date!
    citizenship: Int!
    legalName: String!
    user: User
  }

  type Credit {
    id: ID!
    canModify: Boolean!
    isPublisher: Boolean!
    isVerified: Boolean!
    role: Int!
    share: Int!
    title: String
    post: Post
    user: User
  }
  
  type Comment {
    id: ID!
    commentCount: Int!
    deletedAt: Datetime
    insertedAt: Datetime!
    text: String!
    post: Post!
    children: [Comment!]
    user: User!
    parent: Comment
  }


  type CuratedPostListing {
    id: ID!
    post: Post!
    curatedList: CuratedList!
    user: User!
  }

  type CuratedList {
    id: ID!
    curatedPostCount: Int!
    curatorCount: Int!
    description: String
    userFollowerCount: Int!
    isFeatured: Boolean!
    name: String!
    curatedPostListings: [CuratedPostListing!]
    curatorialMemberships: [CuratorialMembership!]
  }

  type CuratorialMembership {
    id: ID!
    isOwner: Boolean!
    curatedList: CuratedList!
    user: User!
  }

  type Feature {
    id: ID!
    post: Post!
    curatedList: CuratedList!
    user: User!
  }



  type Tag {
    id: ID!
    name: String!
    discoverableCount: Int!
  }



  input ImageInput {
    id: ID
    caption: String
    extension: String!
    filterIntensity: Int
    previewType: Int
  }

  input MediaCollectionInput {
    id: ID
    mediaItems: [MediaItemInput!]
  }

  input MediaItemInput {
    id: ID
    order: Int!
    image: ImageInput
    video: VideoInput
  }

  input VideoInput {
    id: ID
    caption: String
    duration: Int!
    extension: String!
    filterIntensity: Int
    previewType: Int
  }


  type Image {
    ${imageDef}
  }

  type ImageResponse {
    ${imageDef}
    extension: String!
    uploadUrl: String
    previewUploadUrl: String
  }

  type MediaCollection {
    ${mediaCollectionDef}
    mediaItems(metadata: PaginationMetadataInput): [MediaItem!]
  }

  type MediaCollectionResponse {
    ${mediaCollectionDef}
    mediaItems: [MediaItemResponse!]
  }

  type MediaItem {
    ${mediaItemDef}
    image: Image
    video: Video
  }

  type MediaItemResponse {
    ${mediaItemDef}
    image: ImageResponse
    video: VideoResponse
  }

  type Video {
    ${videoDef}
  }

  type VideoResponse {
    ${videoDef}
    extension: String!
    uploadUrl: String
    previewUploadUrl: String
  }



  type CuratedListFollow {
    follower: User
    followed: CuratedList
  }

  type FeedItem {
    id: ID!
    type: Int!
    post: Post
    curatedList: CuratedList
    user: User
  }

  type UserFollow {
    follower: User!
    followed: User!
  }

  type UserSubscription {
    id: ID!
    activeStarting: Datetime!
    activeUntil: Datetime!
    autorenew: Boolean!
    totalPaid: Float!
    type: Int!
    subscriber: User!
    subscribed: User!
  }

  type Profile {
    id: ID!
    description: String
    website: String
    user: User!
  }

  type PostLike {
    post: Post
    user: User
  }

  type PostPurchase {
    post: Post
    user: User
  }



  input ModerationRequestReportInput {
    comment: String!
    postId: ID
    commentId: ID
    curatedListId: ID
    profileId: ID
  }

  input ModerationRequestResponseInput {
    id: ID
    comment: String
    hasConfirmed: Boolean!
    shouldRemove: Boolean!
    moderationRequestId: ID!
    moderationRequestResponseFieldRevisions: [ModerationRequestResponseFieldRevisionInput!]
    moderationRequestResponseMediaItemActins: [ModerationRequestResponseMediaItemActionInput!]
    moderationRequestResponseUserActions: [ModerationRequestResponseUserActionInput!]
  }

  input ModerationRequestResponseFieldRevisionInput {
    id: ID
    field: String!
    revision: String
  }

  input ModerationRequestResponseMediaItemActionInput {
    id: ID
    shouldDelete: Boolean!
    shouldReport: Boolean!
    mediaItemId: ID!
  }

  input ModerationRequestResponseUserActionInput {
    id: ID
    activeUntil: Datetime
    cannotPostAnything: Boolean!
    cannotPostComments: Boolean!
    cannotPostContent: Boolean!
    cannotModerate: Boolean!
    userId: ID!
  }


  type ModerationRequest {
    id: ID!
    isResolved: Boolean!
    moderationRequestReportCount: Int!
    moderationRequestResponseCount: Int!
    sortedAt: Datetime!
    type: Int!
    
    post: Post
    creatorApplication: CreatorApplication
    curatedList: CuratedList
    comment: Comment
    moderationRequestReports: [ModerationRequestReport!]
    moderationRequestResponses: [ModerationRequestResponse!]
    profile: Profile
  }

  type ModerationRequestReport {
    id: ID!
    comment: String
    insertedAt: Datetime!
    isResolved: Boolean!
    moderationRequest: ModerationRequest
    user: User
  }

  type ModerationRequestResponse {
    id: ID!
    comment: String
    hasConfirmed: Boolean!
    insertedAt: Datetime!
    shouldRemove: Boolean!
    moderationRequest: ModerationRequest!
    moderationRequestResponseFieldRevisions: [ModerationRequestResponseFieldRevision!]
    moderationRequestResponseMediaItemActions: [ModerationRequestResponseMediaItemAction!]
    moderationRequestResponseUserActions: [ModerationRequestResponseUserAction!]
    user: User
  }

  type ModerationRequestResponseFieldRevision {
    id: ID!
    field: String!
    original: String
    revision: String
    moderationRequestResponse: ModerationRequestResponse
  }

  type ModerationRequestResponseMediaItemAction {
    id: ID!
    shouldDelete: Boolean!
    shouldReport: Boolean!
    moderationRequestResponse: ModerationRequestResponse
    mediaItem: MediaItem
  }

  type ModerationRequestResponseUserAction {
    id: ID!
    activeUntil: Datetime
    cannotPostAnything: Boolean!
    cannotPostComments: Boolean!
    cannotPostContent: Boolean!
    cannotModerate: Boolean!
    moderationRequestResponse: ModerationRequestResponse
    user: User
  }

  type UserRestriction {
    id: ID!
    cannotPostAnything: Boolean!
    cannotPostComments: Boolean!
    cannotPostContent: Boolean!
    cannotModerate: Boolean!
  }



  type Notification {
    id: ID!
    isRead: Boolean!
    isSeen: Boolean!
    sortedAt: Datetime!
    type: Int!
    count: Int
    latestUser: User
    post: Post
    comment: Comment
    moderationRequestResponse: ModerationRequestResponse
    user: User
    userRestriction: UserRestriction
  }



  input PaginationMetadataInput {
    limit: Int
    field: Int
    offset: Int
  }

  input PaginationArgsInput {
    postId: ID
    likedPostId: ID
    likerId: ID
    curatedListFollowedId: ID
    curatedListId: ID
    isFeatured: Boolean
    hasPicture: Boolean
    hasVideo: Boolean
    listedPostId: ID
    mediaCollectionId: ID
    userFollowedId: ID
    moderationRequestId: ID
    order: String
    parentId: ID
    purchasedPostId: ID
    purchaserId: ID
    query: String
    userId: ID
  }


  type PaginationMetadata {
    limit: Int!
    offset: Int
  }

  type OffsetBasedPaginatedEntries {
    metadata: PaginationMetadata!
    feed: [PaginatedEntry!]!
    type: String!
  }

  union PaginatedEntry = Post | Comment | CuratedList | FeedItem | MediaItem | ModerationRequest | ModerationRequestReport | ModerationRequestResponse | Notification | User
`;
