import React from 'react';
import { Notification } from 'src/types';
import NotificationItemWelcome from 'src/components/notification-item/types/welcome';
import NotificationItemPostLiked from 'src/components/notification-item/types/post-liked';
import NotificationItemPostPurchased from 'src/components/notification-item/types/post-purchased';
import { READ_NOTIFICATION_MUTATION } from 'src/queries';
import { useMutation } from '@apollo/client';
import NotificationItemPostCreditGiven from 'src/components/notification-item/types/post-credit-given';
import NotificationItemNewModerationRequest from 'src/components/notification-item/types/new-moderation-request';
import NotificationItemCreatorApplicationAccepted from 'src/components/notification-item/types/creator-application-accepted';
import NotificationItemCreatorApplicationRejected from 'src/components/notification-item/types/creator-application-rejected';
import NotificationItemBanned from 'src/components/notification-item/types/banned';
import NotificationItemPostCommentLeft from 'src/components/notification-item/types/post-comment-left';
import NotificationItemPostCommentRepliedTo from 'src/components/notification-item/types/post-comment-replied-to';
import NotificationItemPostApproved from 'src/components/notification-item/types/post-approved';
import NotificationItemPostRejected from 'src/components/notification-item/types/post-rejected';
import NotificationItemNewUserFollower from './types/new-user-follower';
import NotificationNewCuratedListFollower from './types/new-curated-list-follower';
import NotificationItemNewUserSubscriber from './types/new-user-subscriber';

interface InterfaceProps {
  notification: Notification;
  onClick?: () => void;
}

const NotificationItemView: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const [readNotification] = useMutation(READ_NOTIFICATION_MUTATION);

  function augmentedClick(): void {
    if (onClick) onClick();

    if (!notification.isRead) {
      readNotification({
        variables: {
          notificationId: notification.id,
        },
      });
    }
  }

  switch (notification.type) {
    case 0: {
      return (
        <NotificationItemWelcome
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 1: {
      return (
        <NotificationItemPostLiked
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 2: {
      return (
        <NotificationItemPostPurchased
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 4: {
      return (
        <NotificationItemPostCreditGiven
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 5: {
      return (
        <NotificationItemNewUserFollower
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 6: {
      return (
        <NotificationNewCuratedListFollower
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 7: {
      return (
        <NotificationItemNewModerationRequest
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 8: {
      return (
        <NotificationItemCreatorApplicationAccepted
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 9: {
      return (
        <NotificationItemCreatorApplicationRejected
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 10: {
      return (
        <NotificationItemBanned
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 12: {
      return (
        <NotificationItemPostCommentLeft
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 13: {
      return (
        <NotificationItemPostCommentRepliedTo
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 14: {
      return (
        <NotificationItemNewUserSubscriber
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 16: {
      return (
        <NotificationItemPostApproved
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    case 17: {
      return (
        <NotificationItemPostRejected
          notification={notification}
          onClick={augmentedClick}
        />
      );
    }
    default:
      return <></>;
  }
};

export default NotificationItemView;
