import React from 'react';
import { Notification } from 'src/types';
import NotificationItemUserInteraction from 'src/components/notification-item/types/user-interaction';
import NotificationItemWrapper from 'src/components/notification-item/notification-item-wrapper';
import { useNavigate } from 'react-router';
import { postTitle } from 'src/utils/formatting';

interface InterfaceProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItemPostCommentRepliedTo: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const navigate = useNavigate();

  function augmentedClick(): void {
    if (notification.post) {
      navigate({
        pathname: `/posts/${notification.post.id}/comments`,
        search: `?commentId=${notification.comment.id}&parentId=${notification.comment.parent.id}`,
      });
    }
    onClick();
  }

  return (
    <NotificationItemWrapper
      notification={notification}
      onClick={augmentedClick}
    >
      <NotificationItemUserInteraction
        contentDeleted={!notification.post}
        name={postTitle(notification.post)}
        notification={notification}
        type="PostCommentRepliedTo"
      />
    </NotificationItemWrapper>
  );
};

export default NotificationItemPostCommentRepliedTo;
