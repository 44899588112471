/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { FOLLOW_USER_MUTATION } from 'src/queries/membership';
import { User } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { updateCurrentUser, updatePaginatedUsers } from 'src/utils/data';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';

export function useFollowUser(
  followed: User
): (_?: any, options?: any) => Promise<void> {
  const ensureUser = useEnsureUser();
  const { t } = useTranslation('general');
  const [followUserMutation] = useMutation(FOLLOW_USER_MUTATION, {
    update(cache, { data: { followUser } }) {
      updateCurrentUser(cache, (currentUser: User) => {
        return {
          ...currentUser,
          ...followUser.follower,
        };
      });

      cache.writeFragment({
        id: `User:${followed.id}`,
        fragment: gql`
          fragment post on Post {
            userFollowerCount
            userHasFollowed
          }
        `,
        data: {
          userFollowerCount: followed.userFollowerCount + 1,
          userHasFollowed: true,
        },
      });

      updatePaginatedUsers(
        cache,
        {
          followerId: followUser.follower.id,
        },
        (users) => {
          return [followUser.followed.user, ...users];
        }
      );
    },
  });
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_?: any, options?: any): Promise<void> => {
    try {
      if (!followUserMutation) return;

      await ensureUser();

      await followUserMutation({
        variables: {
          followedId: followed.id,
        },
      });

      toast(
        t('followFollowed', {
          username: followed.username,
        })
      );
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (options && options.setSubmitting) options.setSubmitting(false);
    }
  };
}
