import React from 'react';
import { Notification } from 'src/types';
import { useTranslation } from 'react-i18next';

interface InterfaceProps {
  contentDeleted?: boolean;
  name?: string;
  notification: Notification;
  type: string;
}

const NotificationItemUserInteraction: React.FC<InterfaceProps> = ({
  contentDeleted,
  name,
  notification,
  type,
}) => {
  const { t } = useTranslation('general');

  if (contentDeleted) {
    return t('notificationsDeletedContent');
  } else if (notification.latestUser) {
    const translation =
      notification.count > 1
        ? `notifications${type}WithUserAndMany`
        : `notifications${type}WithUser`;
    return t(translation, {
      name: name,
      count: notification.count - 1,
      user: `@${notification.latestUser.username}`,
    });
  } else {
    return t(`notifications${type}`, {
      name: name,
      count: notification.count,
    });
  }
};

export default NotificationItemUserInteraction;
