import React from 'react';
import { PaginatableType, Notification } from 'src/types';
import InfiniteScroll from 'src/components/infinite-scroll';
import NoContent from 'src/components/no-content';
import ListView from 'src/components/list-view';
import NotificationItem from 'src/components/notification-item/index';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles';

const NotificationList = styled(ListView)`
  margin: 0;

  li {
    margin-bottom: 1rem;
  }

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    margin: ${fibonacci(1) * goldenRatioInverse}rem;
  }
`;

interface InterfaceProps {
  className?: string;
  entries: PaginatableType[];
  onClick?: () => void;
  onLoadMore: () => Promise<void>;
}

const NotificationListView: React.FC<InterfaceProps> = ({
  className,
  entries,
  onClick,
  onLoadMore,
}) => {
  if (!entries || entries.length === 0) {
    return <NoContent></NoContent>;
  }

  return (
    <InfiniteScroll onApproachingInfinity={onLoadMore}>
      <NotificationList className={className}>
        {entries.map((notification) => {
          notification = notification as Notification;

          return (
            <NotificationItem
              key={notification.id}
              notification={notification as Notification}
              onClick={onClick}
            />
          );
        })}
      </NotificationList>
    </InfiniteScroll>
  );
};

export default NotificationListView;
