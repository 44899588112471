/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useState } from 'react';
import { DeemphasizedButton, StyledButton } from 'src/styles';
import { User } from 'src/types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/use-auth';
import { Formik, Form } from 'formik';
import { useUnfollowUser } from 'src/hooks/use-unfollow-user';
import { useFollowUser } from 'src/hooks/use-follow-user';
import { useReloadAndViewUser } from 'src/hooks/use-reload-and-view-user';
import { useSetAutorenew } from 'src/hooks/use-set-autorenew';
import { useSubscribe } from 'src/hooks/use-subscribe';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const StyledForm = styled(Form)`
  flex-grow: 1;
  flex-shrink: 0;
`;

const RestyledButton = styled(StyledButton)`
  width: 100%;
`;

const RestyledDeemphasizedButton = styled(DeemphasizedButton)`
  width: 100%;
`;

interface InterfaceProps {
  toggleEdit: () => void;
  user: User;
}

const UserSummaryPrimaryButton: React.FC<InterfaceProps> = ({
  toggleEdit,
  user,
}) => {
  const { user: authUser } = useAuth();
  const { t } = useTranslation('general');
  const onUnfollow = useUnfollowUser(user);
  const onFollow = useFollowUser(user);
  const onSubscribe = useSubscribe(user);
  const onReloadCurrentUser = useReloadAndViewUser(user);
  const currentUserIsUser = authUser && authUser.id === user.id;
  const [isFollowing, setIsFollowing] = useState(user.userHasFollowed);
  const follow = useCallback(async () => {
    await onFollow(null);
    setIsFollowing(true);
  }, [setIsFollowing]);
  const unfollow = useCallback(async () => {
    await onUnfollow(null);
    setIsFollowing(false);
  }, [setIsFollowing]);
  const latestSubscription = authUser
    ? authUser.activeUserSubscriptions
        .filter((sub) => sub.subscribed.id === user.id)
        .sort((a, b) => {
          return (
            new Date(a.activeUntil).getTime() -
            new Date(b.activeUntil).getTime()
          );
        })[0]
    : undefined;
  const onCancelSubscription = useSetAutorenew(false, latestSubscription);
  const onRenewSubscription = useSetAutorenew(true, latestSubscription);

  async function onSubscribeAndReload() {
    await onSubscribe(null, {});
    await onReloadCurrentUser();
  }

  if (currentUserIsUser) {
    return (
      <StyledButton onClick={toggleEdit}>{t('profilesEdit')}</StyledButton>
    );
  } else {
    return (
      <Container>
        {user.subscriptionPostCount === 0 ? (
          isFollowing ? (
            <Formik initialValues={{}} onSubmit={unfollow}>
              {({ isSubmitting }): ReactElement => (
                <StyledForm>
                  <RestyledButton type="submit" disabled={isSubmitting}>
                    {t('followUnfollow')}
                  </RestyledButton>
                </StyledForm>
              )}
            </Formik>
          ) : (
            <Formik initialValues={{}} onSubmit={follow}>
              {({ isSubmitting }): ReactElement => (
                <StyledForm>
                  <RestyledButton type="submit" disabled={isSubmitting}>
                    {t('followFollow')}
                  </RestyledButton>
                </StyledForm>
              )}
            </Formik>
          )
        ) : null}
        {latestSubscription ? (
          <>
            {latestSubscription.autorenew ? (
              <Formik initialValues={{}} onSubmit={onCancelSubscription}>
                {({ isSubmitting }): ReactElement => (
                  <StyledForm>
                    <RestyledDeemphasizedButton
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {t('subscribeCancelSubscription')}
                    </RestyledDeemphasizedButton>
                  </StyledForm>
                )}
              </Formik>
            ) : (
              <Formik initialValues={{}} onSubmit={onRenewSubscription}>
                {({ isSubmitting }): ReactElement => (
                  <StyledForm>
                    <RestyledButton type="submit" disabled={isSubmitting}>
                      {t('subscribeRenewSubscription')}
                    </RestyledButton>
                  </StyledForm>
                )}
              </Formik>
            )}
          </>
        ) : user.subscriptionPostCount > 0 ? (
          <Formik initialValues={{}} onSubmit={onSubscribeAndReload}>
            {({ isSubmitting }): ReactElement => (
              <StyledForm>
                <RestyledButton type="submit" disabled={isSubmitting}>
                  {t('subscribeSubscribeOrFollow')}
                </RestyledButton>
              </StyledForm>
            )}
          </Formik>
        ) : null}
      </Container>
    );
  }
};

export default UserSummaryPrimaryButton;
