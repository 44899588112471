import React from 'react';
import styled from 'styled-components';
import { Comment, Post } from 'src/types';
import {
  fibonacci,
  millisecondsInAMonth,
  goldenRatioInverse,
} from 'src/utils/math';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/use-auth';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import { useMutation } from '@apollo/client';
import { DELETE_COMMENT_MUTATION } from 'src/queries';
import { updatePaginatedComments } from 'src/utils/data';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { useEnsureUser } from 'src/hooks/use-ensure-user';

const Control = styled.a`
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
`;

const Interface = styled.div`
  margin-top: ${fibonacci(1) * goldenRatioInverse}rem;
`;

const Date = styled(Moment)`
  color: rgba(${(props): string => props.theme.highMutedColor}, 1);
`;

interface InterfaceProps {
  post: Post;
  comment: Comment;
  openReply: () => void;
  parent?: Comment;
  toggleIsEditing: () => void;
}

const CommentCardInterface: React.FC<InterfaceProps> = ({
  post,
  comment,
  openReply,
  parent,
  toggleIsEditing,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const confirm = useConfirmationModal();
  const { openModal } = useAccountModals();
  const ensureUser = useEnsureUser();
  const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION, {
    update(cache, { data: { deleteComment } }) {
      const paginationArgs = parent
        ? { parentId: parent.id }
        : { postId: post.id };
      updatePaginatedComments(cache, paginationArgs, (comments) => {
        return deleteComment.comment.deletedAt
          ? comments
          : comments.filter(
              (comment) => deleteComment.comment.id !== comment.id
            );
      });
    },
  });

  const deleteCommentConfirmation = async (): Promise<void> => {
    if (await confirm(t('commentsDeleteConfirm'))) {
      deleteComment({
        variables: {
          commentId: comment.id,
        },
      });
    }
  };

  const report = async (): Promise<void> => {
    await ensureUser();
    if (openModal) await openModal('reportComment', { comment });
  };

  return (
    <Interface>
      <Control onClick={openReply}>{t('commentsReply')}</Control>
      {user?.id === comment.user?.id ? (
        <>
          <Control onClick={toggleIsEditing}>{t('commentsEdit')}</Control>
          <Control onClick={deleteCommentConfirmation}>
            {t('commentsDelete')}
          </Control>
        </>
      ) : (
        <Control onClick={report}>{t('commentsReport')}</Control>
      )}
      <Date
        date={comment.insertedAt}
        fromNowDuring={millisecondsInAMonth}
        format="MM/DD/YY"
      />
    </Interface>
  );
};

export default CommentCardInterface;
