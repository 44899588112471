import { User, Post, PostAccessType } from 'src/types';

export function checkIfUserFollowsUser(
  follower: User | undefined,
  followed: User
): boolean {
  return followed.userHasFollowed;
}

export function checkIfUserIsPostCreator(post: Post): boolean {
  return post.userIsCredited;
}

export function checkIfUserHasLikedPost(post: Post): boolean {
  return post.userHasLiked;
}

export function checkIfUserHasPurchasedPost(post: Post): boolean {
  return post.userHasPurchased;
}

export function checkIfUserHasSubscribedToAnPostCreator(
  user: User | undefined,
  post: Post
): boolean {
  if (!user) return false;

  return user.activeUserSubscriptions.some((us) => {
    return post.credits.some((c) => c.user.id === us.subscribed.id);
  });
}

export function checkIfUserHasListedPost(
  user: User | undefined,
  post: Post
): boolean {
  if (!user) return false;

  return user?.curatorialMemberships?.some(
    ({ curatedList: { curatedPostListings } }) =>
      curatedPostListings.some(({ post: { id } }) => id === post.id)
  );
}

export function checkIfUserCanListPost(
  user: User | undefined,
  post: Post
): boolean {
  return checkIfUserHasLikedPost(post) || checkIfUserHasPurchasedPost(post);
}

export function checkIfUserMustPayForPost(
  user: User | undefined,
  post: Post
): boolean {
  return post.accessType === PostAccessType['post-access-type-pay'];
}

export function checkIfUserMustSubscribeForPost(
  user: User | undefined,
  post: Post
): boolean {
  return post.accessType === PostAccessType['post-access-type-subscription'];
}
