import React, { useState } from 'react';
import styled from 'styled-components';
import { Comment, Post } from 'src/types';
import UsernameView from 'src/components/username';
import { Avatar } from 'src/components/avatar';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles/global';
import CommentCardInterface from 'src/components/comment-card/comment-card-interface';
import CommentList from 'src/components/comment-list';
import CommentForm from 'src/components/comment-form';
import { PrimaryColorLink } from 'src/styles';
import CommentEditForm from 'src/components/comment-edit-form';
import { useTranslation } from 'react-i18next';

interface ContainerProps {
  isActiveComment: boolean;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  isActiveComment: props.isActiveComment,
}))`
  display: flex;
  ${(props): string | undefined => {
    if (props.isActiveComment) {
      return `
        padding-left: ${fibonacci(3)}rem;
        border-left: solid 1rem rgba(${props.theme.primaryColor}, 1);
      `;
    }
  }}
`;

const AvatarContainer = styled(PrimaryColorLink)`
  display: flex;
  flex-direction: column;
  flex-justify: center;
  height: 100%;
  margin-right: ${fibonacci(3)}rem;
`;

const StyledAvatar = styled(Avatar)`
  width: ${fibonacci(5)}rem;
  height: ${fibonacci(5)}rem;
`;

const Deleted = styled.div`
  min-height: 4rem;
  opacity: ${goldenRatioInverse};
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

const Name = styled(UsernameView)`
  display: inline-block;
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  font-weight: bold;
  margin-right: 1rem;
`;

const Replies = styled.div`
  margin-top: ${fibonacci(3)}rem;
`;

interface InterfaceProps {
  activeCommentId?: string;
  activeParentId?: string;
  post: Post;
  comment: Comment;
  parent?: Comment;
  openReply?: () => void;
}

const CommentCardView: React.FC<InterfaceProps> = ({
  activeCommentId,
  activeParentId,
  post,
  comment,
  parent,
  openReply,
}) => {
  const { t } = useTranslation('general');
  const { user } = comment;
  const [replyIsOpen, setReplyIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const setReplyToOpen = (): void => {
    setReplyIsOpen(true);
  };

  const setReplyToClosed = (): void => {
    setReplyIsOpen(false);
  };

  const toggleIsEditing = (): void => {
    setIsEditing(!isEditing);
  };

  return (
    <Container isActiveComment={activeCommentId === comment.id}>
      <AvatarContainer to={`/${comment.user.username}`}>
        <StyledAvatar avatar={user.avatar} size={4} />
      </AvatarContainer>
      <MainContent>
        {comment.deletedAt ? (
          <Deleted>{t('commentsIsDeleted')}</Deleted>
        ) : isEditing ? (
          <CommentEditForm
            toggleIsEditing={toggleIsEditing}
            comment={comment}
          />
        ) : (
          <>
            <Name user={user} />
            <span>{comment.text}</span>
            <CommentCardInterface
              post={post}
              comment={comment}
              openReply={openReply || setReplyToOpen}
              parent={parent}
              toggleIsEditing={toggleIsEditing}
            />
          </>
        )}
        {!parent && (
          <Replies>
            {comment.commentCount > 0 && (
              <CommentList
                activeCommentId={
                  activeParentId === comment.id ? activeCommentId : undefined
                }
                post={post}
                parent={comment}
                paginationArgs={{
                  parentId: comment.id,
                }}
                openReply={setReplyToOpen}
              />
            )}
            {replyIsOpen && (
              <CommentForm
                post={post}
                parent={comment}
                onExit={setReplyToClosed}
              />
            )}
          </Replies>
        )}
      </MainContent>
    </Container>
  );
};

export default CommentCardView;
