import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SecondaryNavBar } from 'src/components/nav-bar';
import { User } from 'src/types';

interface InterfaceProps {
  user: User;
}

const UserFollowingRouteSelector: React.FC<InterfaceProps> = ({ user }) => {
  const { t } = useTranslation('general');
  const options = [];

  options.push({
    to: '',
    end: true,
    preventScrollReset: true,
    scrollToNavOnClick: true,
    content: t('followingMembersWithCount', {
      count: user.userFollowingCount,
    }),
  });

  if (user.subscribedCount > 0) {
    options.push({
      to: `subscriptions`,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('subscriptionsWithCount', {
        count: user.subscribedCount,
      }),
    });
  }

  options.push({
    to: 'lists',
    preventScrollReset: true,
    scrollToNavOnClick: true,
    content: t('followingListsWithCount', {
      count: user.curatedListFollowingCount,
    }),
  });

  return (
    <>
      {user.curatedListFollowingCount > 0 ? (
        <SecondaryNavBar>{options}</SecondaryNavBar>
      ) : null}
      <Outlet context={{ user }} />
    </>
  );
};

export default UserFollowingRouteSelector;
