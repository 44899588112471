import React from 'react';
import { PaginatableType, ModerationRequestReport } from 'src/types';
import NoContent from 'src/components/no-content';
import {
  fibonacci,
  goldenRatioInverse,
  millisecondsInAMonth,
} from 'src/utils/math';
import styled from 'styled-components';
import UsernameView from 'src/components/username';
import { useTranslation } from 'react-i18next';
import {
  PrimaryColorLink,
  PrimaryColorVanillaLink,
} from 'src/styles/components/link';
import { Avatar } from '../avatar';
import { GlobalStyleVariables } from 'src/styles';
import Moment from 'react-moment';

const LoadMoreLink = styled(PrimaryColorVanillaLink)`
  display: block;
  margin: ${fibonacci(1) * goldenRatioInverse}rem;
`;

const AvatarContainer = styled(PrimaryColorLink)`
  display: flex;
  flex-direction: column;
  flex-justify: center;
  height: 100%;
  margin-right: 1rem;
`;

const StyledAvatar = styled(Avatar)`
  width: ${fibonacci(5)}rem;
  height: ${fibonacci(5)}rem;
`;

interface ReportProps {
  isResolved: boolean;
}

const Report = styled.div.attrs((props: ReportProps) => ({
  isResolved: props.isResolved,
}))`
  display: flex;
  margin-top: 1rem;
  ${(props): string | undefined => {
    if (props.isResolved) {
      return `
        opacity: ${goldenRatioInverse};
      `;
    }
  }}
`;

const InnerContainer = styled.div`
  display: flex;
`;

const Name = styled(UsernameView)`
  display: inline-block;
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  font-weight: bold;
`;

const ReportComment = styled.div`
  margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem;
  margin-left: 1rem;
`;

const Date = styled(Moment)`
  display: block;
  margin-top: ${fibonacci(1) * goldenRatioInverse}rem;
  color: rgba(${(props): string => props.theme.highMutedColor}, 1);
`;

interface InterfaceProps {
  entries: PaginatableType[];
  onLoadMore: () => Promise<void>;
  reportCount: number;
}

const ModerationRequestItemReportsEntries: React.FC<InterfaceProps> = ({
  entries,
  onLoadMore,
  reportCount,
}) => {
  const { t } = useTranslation('general');

  if (!entries || entries.length === 0) {
    return <NoContent></NoContent>;
  }

  return (
    <div>
      {reportCount > entries.length && (
        <LoadMoreLink onClick={onLoadMore}>
          {t('moderationReportLoadMore')}
        </LoadMoreLink>
      )}
      {entries
        .slice(0)
        .reverse()
        .map((moderationRequestReport) => {
          moderationRequestReport =
            moderationRequestReport as ModerationRequestReport;
          return (
            <Report
              key={moderationRequestReport.id}
              isResolved={moderationRequestReport.isResolved}
            >
              <AvatarContainer to={`/${moderationRequestReport.user.username}`}>
                <StyledAvatar
                  avatar={moderationRequestReport.user.avatar}
                  size={4}
                />
              </AvatarContainer>
              <div>
                <InnerContainer>
                  <Name user={moderationRequestReport.user} />
                  <ReportComment>
                    {moderationRequestReport.comment}
                  </ReportComment>
                </InnerContainer>
                <Date
                  date={moderationRequestReport.insertedAt}
                  fromNowDuring={millisecondsInAMonth}
                  format="MM/DD/YY"
                />
              </div>
            </Report>
          );
        })}
    </div>
  );
};

export default ModerationRequestItemReportsEntries;
