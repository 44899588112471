/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { StyledButton } from 'src/styles';
import { Post } from 'src/types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { useAuth } from 'src/hooks/use-auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePurchasePost } from 'src/hooks/use-purchase-post';
import { useReloadAndViewPost } from 'src/hooks/use-reload-and-view-post';
import { useSubscribe } from 'src/hooks/use-subscribe';
import { InvertingHoverButton } from 'src/styles/components/button';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { useLikePost } from 'src/hooks/use-like-post';
import { useDelikePost } from 'src/hooks/use-unlike-post';
import {
  checkIfUserHasLikedPost,
  checkIfUserHasPurchasedPost,
  checkIfUserHasSubscribedToAnPostCreator,
  checkIfUserMustPayForPost,
  checkIfUserMustSubscribeForPost,
  checkIfUserHasListedPost,
  checkIfUserIsPostCreator,
} from 'src/utils/computes';

const StyledForm = styled(Form)`
  display: flex;
`;

const ButtonText = styled.div`
  margin-right: 1rem;
`;

interface InterfaceProps {
  post: Post;
}

const PostSummaryPrimaryButton: React.FC<InterfaceProps> = ({ post }) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const { openModal } = useAccountModals();
  const onLike = useLikePost(post);
  const onDelike = useDelikePost(post);
  const onPurchase = usePurchasePost(post);
  const onSubscribe = useSubscribe(post.credits[0].user);
  const onReloadCurrentPost = useReloadAndViewPost(post);
  const isPostCreator = checkIfUserIsPostCreator(post);
  const hasLiked = checkIfUserHasLikedPost(post);
  const hasPaid = checkIfUserHasPurchasedPost(post);
  const hasSubscribed = checkIfUserHasSubscribedToAnPostCreator(user, post);
  const hasListed = checkIfUserHasListedPost(user, post);
  const mustPay = checkIfUserMustPayForPost(user, post);
  const mustSubscribe = checkIfUserMustSubscribeForPost(user, post);

  async function addToCuratedList(
    _: any,
    { setSubmitting }: any
  ): Promise<void> {
    try {
      if (!openModal) return;

      openModal('addToCuratedList', { post, hasListed });
    } finally {
      setSubmitting(false);
    }
  }

  async function onSubscribeAndReload() {
    await onSubscribe(null, {});
    await onReloadCurrentPost();
  }

  if (mustSubscribe && !hasSubscribed && !isPostCreator) {
    return (
      <Formik initialValues={{}} onSubmit={onSubscribeAndReload}>
        {({ isSubmitting }): ReactElement => (
          <StyledForm>
            <StyledButton type="submit" disabled={isSubmitting}>
              {t('subscribeSubscribeOrFollow')}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    );
  } else if (mustPay && !hasPaid && !isPostCreator) {
    return (
      <Formik initialValues={{}} onSubmit={onPurchase}>
        {({ isSubmitting }): ReactElement => (
          <StyledForm>
            <StyledButton type="submit" disabled={isSubmitting}>
              {t('price', { amount: post.price })}
            </StyledButton>
          </StyledForm>
        )}
      </Formik>
    );
  } else {
    return (
      <>
        <Formik initialValues={{}} onSubmit={addToCuratedList}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <StyledButton type="submit" disabled={isSubmitting}>
                <ButtonText>
                  {hasListed
                    ? t('curatedListsManageListings')
                    : t('curatedListsAddTo')}
                </ButtonText>
                <FontAwesomeIcon icon="list" />
              </StyledButton>
            </StyledForm>
          )}
        </Formik>
        {hasLiked ? (
          <Formik initialValues={{}} onSubmit={onDelike}>
            {({ isSubmitting }): ReactElement => (
              <StyledForm>
                <InvertingHoverButton type="submit" disabled={isSubmitting}>
                  <FontAwesomeIcon icon={['fas', 'star']} />
                </InvertingHoverButton>
              </StyledForm>
            )}
          </Formik>
        ) : (
          <Formik initialValues={{}} onSubmit={onLike}>
            {({ isSubmitting }): ReactElement => (
              <StyledForm>
                <InvertingHoverButton type="submit" disabled={isSubmitting}>
                  <FontAwesomeIcon icon={['far', 'star']} />
                </InvertingHoverButton>
              </StyledForm>
            )}
          </Formik>
        )}
      </>
    );
  }
};

export default PostSummaryPrimaryButton;
