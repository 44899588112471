import React, { Fragment } from 'react';
import { ModerationRequestResponse } from 'src/types';
import {
  fibonacci,
  goldenRatioInverse,
  millisecondsInAMonth,
} from 'src/utils/math';
import styled from 'styled-components';
import UsernameView from 'src/components/username';
import { useTranslation } from 'react-i18next';
import DiffedText from 'src/components/diffed-text';
import { GlobalStyleVariables, PrimaryColorLink } from 'src/styles';
import { Avatar } from '../avatar';
import Moment from 'react-moment';

const AvatarContainer = styled(PrimaryColorLink)`
  display: flex;
  flex-direction: column;
  flex-justify: center;
  height: 100%;
  margin-right: 1rem;
`;

const StyledAvatar = styled(Avatar)`
  width: ${fibonacci(5)}rem;
  height: ${fibonacci(5)}rem;
`;

const Response = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const InnerContainer = styled.div`
  display: flex;
`;

const Name = styled(UsernameView)`
  display: inline-block;
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  font-weight: bold;
`;

const ResponseComment = styled.div`
  margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem;
  margin-left: 1rem;
`;

const Date = styled(Moment)`
  display: block;
  margin-top: ${fibonacci(1) * goldenRatioInverse}rem;
  color: rgba(${(props): string => props.theme.highMutedColor}, 1);
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: ${fibonacci(1) * Math.pow(goldenRatioInverse, 3)}rem;
  margin-left: 1rem;
`;

const ModerationActions = styled.div`
  margin-left: ${fibonacci(1) * Math.pow(goldenRatioInverse, 2)}rem;

  > * {
    :after {
      content: ', ';
    }

    &:last-child {
      :after {
        content: '';
      }
    }
  }
`;

interface InterfaceProps {
  moderationRequestResponses: ModerationRequestResponse[];
  isDisciplinary?: boolean;
}

const ModerationRequestItemResponsesEntries: React.FC<InterfaceProps> = ({
  moderationRequestResponses,
  isDisciplinary,
}) => {
  const { t } = useTranslation('general');

  return (
    <div>
      {moderationRequestResponses
        .slice(0)
        .reverse()
        .map((moderationRequestResponse) => {
          moderationRequestResponse =
            moderationRequestResponse as ModerationRequestResponse;
          const actions =
            (moderationRequestResponse.moderationRequestResponseUserActions &&
              moderationRequestResponse.moderationRequestResponseUserActions.map(
                (userAction) => {
                  const restrictions = [];

                  if (userAction?.cannotModerate) {
                    restrictions.push(t('userRestrictionCannotModerate'));
                  }

                  if (userAction?.cannotPostAnything) {
                    restrictions.push(t('userRestrictionCannotPostAnything'));
                  }

                  if (userAction?.cannotPostComments) {
                    restrictions.push(t('userRestrictionCannotPostComments'));
                  }

                  if (userAction?.cannotPostContent) {
                    restrictions.push(t('userRestrictionCannotPostContent'));
                  }

                  return (
                    <Fragment key={userAction.id}>
                      <PrimaryColorLink to={`/${userAction.user.username}`}>
                        {restrictions.length > 0
                          ? userAction.activeUntil
                            ? t('moderationResponseUserRestrictionAddedUntil', {
                                restrictions,
                                until: userAction.activeUntil,
                                user: userAction.user.username,
                              })
                            : t('moderationResponseUserRestrictionAdded', {
                                restrictions,
                                user: userAction.user.username,
                              })
                          : t('moderationResponseUserRestrictionRemoved', {
                              user: userAction.user.username,
                            })}
                      </PrimaryColorLink>
                    </Fragment>
                  );
                }
              )) ??
            [];

          if (moderationRequestResponse.shouldRemove) {
            actions.push(
              <Fragment key="shouldRemove">
                {t('moderationResponseDeletedContent')}
              </Fragment>
            );
          }
          return (
            <Response key={moderationRequestResponse.id}>
              <AvatarContainer
                to={`/${moderationRequestResponse.user.username}`}
              >
                <StyledAvatar
                  avatar={moderationRequestResponse.user.avatar}
                  size={4}
                />
              </AvatarContainer>
              <div>
                <InnerContainer>
                  <Name user={moderationRequestResponse.user} />
                  {isDisciplinary ? (
                    <ActionContainer>
                      {moderationRequestResponse.hasConfirmed
                        ? t('moderationResponseTookAction')
                        : t('moderationResponseDismissed')}
                      {': '}
                      <ModerationActions>
                        {actions}
                        {moderationRequestResponse.moderationRequestResponseFieldRevisions &&
                          moderationRequestResponse.moderationRequestResponseFieldRevisions.map(
                            (fieldRevision) => {
                              return (
                                <span key={fieldRevision.id}>
                                  {t('moderationResponseFieldRevision', {
                                    field: fieldRevision.field,
                                  })}
                                  {`: "`}
                                  <DiffedText
                                    original={fieldRevision.original}
                                    revision={fieldRevision.revision}
                                  />
                                  {`"`}
                                </span>
                              );
                            }
                          )}
                      </ModerationActions>
                    </ActionContainer>
                  ) : (
                    <ResponseComment>
                      {moderationRequestResponse.hasConfirmed
                        ? t('moderationResponseAccepted')
                        : t('moderationResponseRejected')}
                    </ResponseComment>
                  )}
                  {moderationRequestResponse.comment && (
                    <ResponseComment>
                      {moderationRequestResponse.comment}
                    </ResponseComment>
                  )}
                </InnerContainer>
                <Date
                  date={moderationRequestResponse.insertedAt}
                  fromNowDuring={millisecondsInAMonth}
                  format="MM/DD/YY"
                />
              </div>
            </Response>
          );
        })}
    </div>
  );
};

export default ModerationRequestItemResponsesEntries;
