import React, { Fragment } from 'react';
import { Post, PostAccessType } from 'src/types';
import { MutedLink } from 'src/styles';
import { useTranslation } from 'react-i18next';
import { formatDuration } from 'src/utils/formatting';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  checkIfUserHasSubscribedToAnPostCreator,
  checkIfUserIsPostCreator,
} from 'src/utils/computes';
import { useAuth } from 'src/hooks/use-auth';
import Moment from 'react-moment';
import { goldenRatioInverse } from 'src/utils/math';

const MutedDiv = styled.div`
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
`;

const MarginLeft = styled.span`
  display: inline-block;
  margin-left: 1rem;
`;

const Red = styled.span`
  color: rgba(
    ${(props): string => props.theme.errorColor},
    ${goldenRatioInverse}
  );
`;

interface InterfaceProps {
  post: Post;
}

const PostSummarySumplementaryInfo: React.FC<InterfaceProps> = ({ post }) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const {
    commentCount,
    likeCount,
    imageCount,
    purchaseCount,
    videoCount,
    videoDuration,
  } = post;
  const formattedDuration = formatDuration(videoDuration);
  const isPostCreator = checkIfUserIsPostCreator(post);
  const hasSubscribed = checkIfUserHasSubscribedToAnPostCreator(user, post);

  return (
    <Fragment>
      {!post.isApproved && (
        <MutedDiv>
          <FontAwesomeIcon icon="clock" />
          <MarginLeft>{t('postsNotYetApproved')}</MarginLeft>
        </MutedDiv>
      )}

      {post.accessType === PostAccessType['post-access-type-pay'] ? (
        <MutedDiv>{t('price', { amount: post.price })}</MutedDiv>
      ) : post.accessType === PostAccessType['post-access-type-free'] ? (
        <MutedDiv>{t('post-access-type-free')}</MutedDiv>
      ) : !isPostCreator && !hasSubscribed ? (
        <MutedDiv>
          <FontAwesomeIcon icon="lock" />
        </MutedDiv>
      ) : (
        <MutedDiv>
          <FontAwesomeIcon icon="unlock" />
        </MutedDiv>
      )}

      {imageCount > 0 && (
        <MutedDiv>{t('imagesWithCount', { count: imageCount })}</MutedDiv>
      )}

      {videoCount > 0 && (
        <MutedDiv>
          {t('videosWithCountAndDuration', {
            count: videoCount,
            duration: formattedDuration,
          })}
        </MutedDiv>
      )}

      {likeCount > 0 && (
        <MutedLink to={`/posts/${post.id}/likes`} preventScrollReset={true}>
          {post.likeCount} <FontAwesomeIcon icon="star" />
        </MutedLink>
      )}

      {purchaseCount > 0 && (
        <MutedLink
          to={`/posts/${post.id}/likes/buyers`}
          preventScrollReset={true}
        >
          {t('purchasesWithCount', {
            count: purchaseCount,
          })}
        </MutedLink>
      )}

      {commentCount > 0 && (
        <MutedLink to={`/posts/${post.id}#comments`} preventScrollReset={true}>
          {commentCount} <FontAwesomeIcon icon="comment" />
        </MutedLink>
      )}

      <MutedDiv>
        <Moment date={post.publishAt} format="MM/DD/YY" />
      </MutedDiv>

      {post.deletedAt && (
        <MutedDiv>
          <Red>
            <Moment date={post.deletedAt} format="MM/DD/YY" />
          </Red>
        </MutedDiv>
      )}

      {post.features.map((feature) => {
        return (
          <MutedLink key={feature.id} to={`/lists/${feature.curatedList.id}`}>
            {t('curatedListsFeaturedIn', {
              name: feature.curatedList.name,
            })}
          </MutedLink>
        );
      })}
    </Fragment>
  );
};

export default PostSummarySumplementaryInfo;
