import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SecondaryNavBar } from 'src/components/nav-bar';
import useUser from '../use-user';

const UserFollowersRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const { user } = useUser();
  const options = [];

  options.push({
    to: ``,
    end: true,
    preventScrollReset: true,
    scrollToNavOnClick: true,
    content: t('followersWithCount', {
      count: user.userFollowerCount,
    }),
  });

  if (user.subscriberCount > 0) {
    options.push({
      to: `subscribers`,
      preventScrollReset: true,
      scrollToNavOnClick: true,
      content: t('subscribersWithCount', {
        count: user.subscriberCount,
      }),
    });
  }

  return (
    <>
      {options.length > 1 && <SecondaryNavBar>{options}</SecondaryNavBar>}
      <Outlet context={{ user }} />
    </>
  );
};

export default UserFollowersRouteView;
