import React from 'react';
import NoContent from 'src/components/no-content';
import { useTranslation } from 'react-i18next';
import CommentList from 'src/components/comment-list/index';
import CommentForm from 'src/components/comment-form';
import { useLocation } from 'react-router';
import usePost from '../use-post';

const PostCommentsRouteView: React.FC = () => {
  const { t } = useTranslation('general');
  const query = new URLSearchParams(useLocation().search);
  const { post } = usePost();

  return (
    <>
      {post.commentCount === 0 ? (
        <NoContent>{t('noComments')}</NoContent>
      ) : (
        <CommentList
          activeCommentId={query.get('commentId') || undefined}
          activeParentId={query.get('parentId') || undefined}
          post={post}
          paginationArgs={{
            postId: post.id,
          }}
        />
      )}
      <span id="comments" />
      <CommentForm post={post} />
    </>
  );
};

export default PostCommentsRouteView;
