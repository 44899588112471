import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { User } from 'src/types';
import { SemiDeemphasizedButton, StyledButton } from 'src/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form } from 'formik';
import { useAuth } from 'src/hooks/use-auth';
import { useFollowUser } from 'src/hooks/use-follow-user';
import { useUnfollowUser } from 'src/hooks/use-unfollow-user';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { fibonacci } from 'src/utils/math';

const StyledForm = styled(Form)`
  display: flex;
`;

const RestyledButton = styled(StyledButton)`
  background: rgba(${(props): string => props.theme.primaryColor}, 1);
  color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  border-style: dotted;
  width: ${fibonacci(6)}rem;
  font-size: ${fibonacci(4)}rem;

  &:focus {
    border-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  }
`;

const RestyledSemiDeemphasizedButton = styled(SemiDeemphasizedButton)`
  width: ${fibonacci(6)}rem;
  font-size: ${fibonacci(4)}rem;

  &:focus {
    border-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  }
`;

interface InterfaceProps {
  user: User;
}

const UserLineItemFollowButton: React.FC<InterfaceProps> = ({ user }) => {
  const { t } = useTranslation('general');
  const onUnfollow = useUnfollowUser(user);
  const onFollow = useFollowUser(user);
  const isFollowing = user.userHasFollowed;

  return (
    <>
      {isFollowing ? (
        <Formik initialValues={{}} onSubmit={onUnfollow}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <RestyledSemiDeemphasizedButton
                type="submit"
                disabled={isSubmitting}
                data-for={`user_${user.id}`}
                data-tip
              >
                <FontAwesomeIcon icon={'user-times'} />
              </RestyledSemiDeemphasizedButton>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <Formik initialValues={{}} onSubmit={onFollow}>
          {({ isSubmitting }): ReactElement => (
            <StyledForm>
              <RestyledButton
                type="submit"
                disabled={isSubmitting}
                data-for={`user_${user.id}`}
                data-tip
              >
                <FontAwesomeIcon icon={'user-plus'} />
              </RestyledButton>
            </StyledForm>
          )}
        </Formik>
      )}
      <ReactTooltip
        id={`user_${user.id}`}
        effect="solid"
        place="bottom"
        getContent={(): string => {
          return isFollowing ? t('followUnfollow') : t('followFollow');
        }}
      />
    </>
  );
};

export default UserLineItemFollowButton;
