import React from 'react';
import { Notification } from 'src/types';
import NotificationItemUserInteraction from 'src/components/notification-item/types/user-interaction';
import NotificationItemWrapper from 'src/components/notification-item/notification-item-wrapper';
import { useNavigate } from 'react-router';
import { useAuth } from 'src/hooks/use-auth';

interface InterfaceProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItemNewUserSubscriber: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  function augmentedClick(): void {
    if (user) {
      navigate(`/${user.username}/followers/subscribers`);
    }
    onClick();
  }

  return (
    <NotificationItemWrapper
      notification={notification}
      onClick={augmentedClick}
    >
      <NotificationItemUserInteraction
        notification={notification}
        type="NewUserSubscriber"
      />
    </NotificationItemWrapper>
  );
};

export default NotificationItemNewUserSubscriber;
